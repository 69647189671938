import { UserRolesEnum } from "../enums/user-role-type.enum";

export type ObjectFields<T> = {
    [P in keyof T]: T[P];
};

export function stringified(val: any): string {
    return typeof (val) === 'string' ? val : val.toString();
}

export function strNullOrEmpty(str: string) {
    return str == null || str.trim() === '';
}

export function sortByObjectKey(sortables: any[], primarySortKey: string = null, secondarySortKey: string = null): any[] {
    if (sortables == null || primarySortKey == null) {
        return null;
    }

    if (secondarySortKey == null) {
        sortables.sort((a, b) => (a[primarySortKey] > b[primarySortKey]) ? 1 : -1);
    } else {
        sortables.sort((a, b) => (a[primarySortKey] > b[primarySortKey]) ?
            1 : (a[primarySortKey] === b[primarySortKey]) ? ((a[secondarySortKey] > b[secondarySortKey]) ? 1 : -1) : -1);
    }

    return sortables;
}

export function SortByObjectKeyCaseSensitive(sortables: any[], primarySortKey: string = null, secondarySortKey: string = null): any[] {
    if (sortables == null || primarySortKey == null) {
        return null;
    }

    const caseInsensitiveCompare = (a: string, b: string): number => {
        return a.localeCompare(b, undefined, { sensitivity: 'base' });
    };

    if (secondarySortKey == null) {
        sortables.sort((a, b) => caseInsensitiveCompare(a[primarySortKey], b[primarySortKey]));
    } else {
        sortables.sort((a, b) => {
            const primaryComparison = caseInsensitiveCompare(a[primarySortKey], b[primarySortKey]);
            if (primaryComparison !== 0) {
                return primaryComparison; // Primary key comparison
            } else {
                return caseInsensitiveCompare(a[secondarySortKey], b[secondarySortKey]); // Secondary key comparison
            }
        });
    }

    return sortables;
}

export function sortByObjectDate(sortables: any[], primarySortKey: string = null, primarySortKeyAlt: string = null) {
    sortables.sort((a, b) => {
        // this sort preferences reverse-chron order... REVIST using params if sort-direction needs configurable
        const dateA = a[primarySortKey];
        const dateAAlt = a[primarySortKeyAlt];
        const dateB = b[primarySortKey];
        const dateBAlt = b[primarySortKeyAlt];

        const useDateA = dateA == null ? dateAAlt : dateA;
        const useDateB = dateB == null ? dateBAlt : dateB;

        const compare = new Date(useDateB).getTime() > new Date(useDateA).getTime() ? 1 : -1;
        // console.log('compare = ' + compare);
        return compare;
    });

    return sortables;
}

export function recordDate(): string {
    return new Date().toJSON();
}

export function jsonEqual(a: any, b: any): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
}

export function objPropEqual(objA: any, objB: any, propName: string): boolean {
    return objA[propName] === objB[propName];
}

export function deepCloneByJson(object: any): any {
    return JSON.parse(JSON.stringify(object));
}

export function chunkArrayBySize(arr, size) {
    var chunked = [];
    for (var i = 0; i < arr.length; i += size) {
        chunked.push(arr.slice(i, i + size));
    }
    return chunked;
}

export function getEnumKeyNames(e: any) {
    return Object.keys(e).filter(k =>
        typeof e[k] === "number"
        || e[k] === k
        || e[e[k]]?.toString() !== k
    );
}

// `PropertyKey` is short for "string | number | symbol"
// since an object key can be any of those types, our key can too
// in TS 3.0+, putting just "string" raises an error
export function hasKey<O extends object>(obj: O, key: PropertyKey): key is keyof O {
    return key in obj
}

export const isNullOrUndefined = <TValue>(value: TValue): boolean => value === null || value === undefined;

export const UserRolesToActionItemTypeValueMap = (): [UserRolesEnum, number[]][] => [
    [UserRolesEnum.NationalTrans,  [9, 8]],
    [UserRolesEnum.Transportation, [9, 8]],
    [UserRolesEnum.Regional,       [9, 8]],
    [UserRolesEnum.OpsManager,     [9, 8]],
    [UserRolesEnum.CorpBilling,    [6, 2, 7, 3]],
    [UserRolesEnum.TSA,            [4, 5]],
    [UserRolesEnum.SalesSupport,   [1]]
];

export const getAllowedActionItemsType = (roles: [UserRolesEnum, number[]][]) => {
    const rolesActionPairs = roles
        .map(([role, values]: [UserRolesEnum, number[]]): [string, boolean][] => {
            const valueMap = values
                .map((value: number): [string, boolean] => [`${role}${value}`, true]);
            return valueMap;
        }).flat();
    return new Map<string, boolean>(rolesActionPairs);
};
